.affiliate {
  display: block;
}

.affiliate .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}

.affiliate .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}

.affiliate .cart {
  text-align: right;
}

.affiliate .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}

.affiliate .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.affiliate .img {
  width: 100%;
}

.affiliate .avatar {
  width: 50px;
  border-radius: 50%;
}

.affiliate .box-item-left {
  padding: 10px 10px 0 10px;
  margin: 20px 5px;
  border-radius: 5px;
  background: linear-gradient(70deg, #ff0080, #ff8000 60%);
  color: #ffffff;
}

.affiliate .box-item-right {
  padding: 10px 10px 0 10px;
  margin: 20px 5px;
  border-radius: 5px;
  background: linear-gradient(70deg, #00b058, #0080ff 60%);
  color: #ffffff;
}

.affiliate .item-left {
  font-size: 1.3em;
  margin: 0;
  padding: 0;
}

.affiliate .item-right {
  font-size: 1.3em;
  margin: 0;
  padding: 0;
  text-align: right;
}

.affiliate .item-img {
  width: 100%;
  border-radius: 5px;
}

.affiliate .form {
  padding: 10px;
}

.affiliate .form-input {
  margin: 5px 0;
}

.affiliate .text {
  color: #666666;
}

.affiliate .text-mini {
  color: #333333;
  font-size: 12px;
}

.affiliate .text-title {
  color: #666666;
  font-size: 16px;
}

.affiliate .form-save {
  font-size: 18px;
  color: #00b058;
}

.affiliate .form-icon {
  font-size: 18px;
  color: #2196f3;
}

.affiliate .link {
  color: #2196f3;
  font-family: inherit;
}

.affiliate .text-primary {
  font-family: "Kanit", Helvetica, Arial, sans-serif;
  color: #333333;
  font-size: 1.2em;
}

.affiliate .text-secondary {
  font-family: "Kanit", Helvetica, Arial, sans-serif;
}

.affiliate .status {
  font-size: 18px;
  font-weight: 100;
}

.affiliate .list-icon {
  font-size: 18px;
  color: #ccc;
  font-weight: normal;
}

.affiliate .crumb {
  font-size: 14px;
  color: #999;
  padding: 10px 5px 0 10px;
}

.affiliate .memtext {
  color: #666666;
  font-size: 15px;
}

.affiliate .box-item {
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 5px;
}

.affiliate .box-biz {
  background-color: #ffffff;
  margin: 5px 0;
  border-radius: 5px;
}

.affiliate .copy {
  justify-content: space-between;
}

.affiliate .tb-member th {
  text-align: left !important;
}

.affiliate .btn-copy {
  font-size: 1em;
  color: #00b058;
  background: linear-gradient(70deg, #eff1f3, #b1e8f2 70%);
}