.profile {
  display: block;
}
.profile .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}
.profile .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}
.profile .cart {
  text-align: right;
}
.profile .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}
.profile .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.box-bg {
  background-color: white;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}
.profile {
  margin-bottom: 30px;
}
.profile .box {
  background-color: var(--bg2);
  border-radius: 5px;
  padding: 0;
  margin: 0 0 10px 0;
}
.profile .box-in {
  background-color: white;
  border-radius: 5px;
  padding: 0;
  margin: 0 0 10px 0;
}
.profile .box .body {
  padding: 10px 5px;
}
.profile .box .title {
  color: 666;
  font-family: inherit;
  padding: 5px 10px;
  margin: 0;
}
.profile .box .detail {
  color: 666;
  font-family: inherit;
  padding: 0 0 5px 10px;
  margin: 0;
  font-size: 16px;
}
.profile .box-member {
  border-radius: 5px;
  padding: 0;
  margin: 10px 0 10px 0;
  color: #666;
  background: white;
}
.profile .box-member .body {
  padding: 10px 5px;
}
.profile .box-member .title {
  font-family: inherit;
  padding: 10px 0 0 10px;
  margin: 0;
  font-size: 1em;
}
.profile .box-member .img {
  border-radius: 5px;
}
.profile .box-member .btn {
  margin: 0;
  padding: 0 0 0 10px;
  font-family: inherit;
}
.profile .divider {
  background: var(--divider);
}
.profile .box-bank {
  margin: 10px 0 10px 0;
}
.profile .box-bank .title {
  color: #666;
  font-family: inherit;
  padding: 5px;
  margin: 0;
}
.profile .box-pass {
  margin: 10px 0 10px 0;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.profile .box-pass .title {
  color: #666666;
  font-family: inherit;
  padding: 0 0 5px 5px;
  margin: 0;
  font-size: 1em;
}
.profile .text-pass {
  color: #666666;
  margin-bottom: 10px;
  background-color: #f0f4fa;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 300;
  font-size: 15px;
}
.profile .MuiFormHelperText-root {
  color: #cd2323;
  font-family: inherit;
  margin: 0 0 10px 10px;
  font-weight: 300;
}
.profile .btn-submit {
  font-family: inherit;
  font-size: 15px;
  border-radius: 5px;
}

.profile .user-img {
  margin: 0;
  padding: 2px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.profile .user {
  display: block;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 3px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.profile .icn {
  margin-right: 7px;
}
.profile .copy {
  margin-top: 3px;
  float: right;
}

.profile .btn-exit {
  background: linear-gradient(90deg, #ec3b2b, #d62607 50%);
  font-family: inherit;
  font-size: 1em;
  border-radius: 5px;
}

.profile .box-header {
  margin: 0;
  padding: 0;
}

.profile .manual-title {
  font-weight: 400;
  font-size: 1.5em;
  color: #ffffff !important;
  padding: 15px 0 0 20px !important;
  margin: 0 !important;
}

.profile .manual-detail {
  font-size: 1.2em;
  color: #f2f2f2 !important;
  background-color: rgb(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.profile .manual-intitle {
  font-weight: 400;
  font-size: 1.5em;
  color: #333333 !important;
  padding: 15px 0 0 20px !important;
  margin: 0 !important;
}

.profile .manual-indetail {
  font-size: 1.2em;
  color: #333333 !important;
  background-color: rgb(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}
