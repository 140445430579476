.shopping {
  display: block;
}

.shopping .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}

.shopping .bill {
  padding: 5px 0 !important;
}

.shopping .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}

.shopping .cart {
  text-align: right;
}

.shopping .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}

.shopping .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.shopping .img {
  width: 100%;
}

.shopping .box-item {
  background-color: #ffffff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.shopping .item-title {
  font-size: 1.3em;
  margin: 0;
  padding: 0 5px;
  color: #666666;
}

.shopping .item-status {
  font-size: 1.2em;
  margin: 0;
  padding: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #15be18;
  border-radius: 5px;
}

.shopping .item-topic {
  font-size: 16px;
  margin: 0;
  padding: 0 5px;
  color: #666666;
}

.shopping .item-img {
  width: 100%;
  border-radius: 5px;
}

.shopping .item-detail {
  font-size: 11px;
  margin: 0;
  padding: 0 5px;
  color: #666666;
}

.shopping .item-date {
  font-size: 1.2em;
  margin: 0;
  padding: 0 5px;
  color: #014f91;
  font-weight: 500;
}

.shopping .item-price {
  font-size: 14px;
  color: #666666;
}

.shopping .item-btn {
  margin: 0;
  padding: 0;
  text-align: center;
}

.shopping .text-cart-head {
  font-size: 20px;
  color: #333333;
  text-align: center;
  margin: 0;
  padding: 0;
}
.shopping .text-cart {
  font-size: 15px;
  color: #666666;
  text-align: right;
}

.shopping .item-amount {
  font-size: 15px;
  color: #666666;
  text-align: right;
}

.shopping .item-sum {
  font-size: 16px;
  color: #666666;
  text-align: right;
}

.shopping .item-box {
  border: 1px solid #cccccc;
  margin: 5px 0;
  padding: 0 5px;
  border-radius: 5px;
}

.shopping .box-price {
  background-color: white;
  border-radius: 5px;
  margin: 10px 0;
  padding: 5px;
}

.shopping .item-cart {
  font-family: inherit;
  padding: 2px 10px;
}

.shopping .product-price {
  font-size: 15px;
  color: #333;
}

.shopping .box-balance {
  background: linear-gradient(90deg, #e5e8f3, #f4f5f6 50%);
  padding: 5px 0;
  margin: 5px 0;
  border-radius: 5px;
}
.shopping .icon {
  padding-left: 20px;
  color: #666666;
}
.shopping .balance {
  padding-right: 20px;
  text-align: right;
  font-size: 1.5em;
}

.shopping .btn-point {
  background: linear-gradient(90deg, #1563e1, #229ad5 50%);
  font-family: inherit;
  font-size: 1.2em;
  border-radius: 5px;
}

.shopping .text-address {
  font-family: inherit;
  margin: 5px 0;
  color: #666666;
}

.shopping .box-point {
  padding: 10px;
  text-align: center;
  font-family: inherit;
  color: #066eb8;
  font-size: 1.2em;
  background: linear-gradient(90deg, #aac5ee, #97d6f5 50%);
  margin: 5px 0;
  font-weight: 500;
  border-radius: 5px;
}

.shopping .point-detail {
  color: #666666;
  padding-left: 10px;
}

.shopping .text-rule {
  font-size: 16px;
  padding: 0;
  margin-top: -1px;
}

.agree .MuiIconButton-label {
  border: 1px solid #066eb8;
  border-radius: 5px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #066eb8 !important;
}

.shopping .text-primary {
  color: #c4d7f8;
}
.shopping .text-secondary {
  color: #b0b0b0;
  font-weight: 100;
}
.shopping .text-winrate {
  color: #dff11c;
  font-weight: 300;
}
.shopping .box-roi {
  background: linear-gradient(90deg, #ffffff, #ffffff 50%);
  padding: 0;
  margin: 0px 0;
  border-radius: 5px;
  color: var(--item3);
}
.shopping .otherimg {
  width: 100%;
  border-radius: 10px;
  border: var(--item1) 2px solid;
}
.shopping .othertext {
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
  margin: 5px;
}
