.footer {
  background: linear-gradient(180deg, #083465, #014f91 70%) !important;
  position: fixed;
  bottom: 0;
  left: 1%;
  right: 1%;
  margin: auto;
  max-width: 414px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* color: #e2e2e2; */
}
/* .footer div,
.footer svg {
  color: #f2f2f2 !important;
} */
.footer .menu {
  color: #4fc2f7;
  font-family: "Kanit", "Helvetica", "Arial", sans-serif;
  background-color: transparent;
}
.footer .icon {
  color: #4fc2f7;
  font-size: 20px;
  margin-top: 5px;
}
.footer .home {
  border-radius: 50%;
  margin-bottom: 20px;
  vertical-align: middle;
  width: 70px;
  height: 70px;
}

.login {
  background-color: white;
  text-align: center;
  color: #666666;
  margin: 50px 10px 20px 10px;
  padding: 20px 20px;
  border-radius: 10px;
}

.btn-signin {
  width: 300px;
  margin: 10px 0 0 0 !important;
  text-align: left !important;
  font-size: 1.1em !important;
  text-transform: none !important;
  border-radius: 5px !important;
  height: 40px;
  box-shadow: none !important;
  padding-left: 6px !important;
  font-family: inherit !important;
  background: linear-gradient(90deg, #536dfe, #2196f3 20%) !important;
}

.text-connect {
  margin: 30px 0 10px 0 !important;
  padding: 0 !important;
  font-family: inherit !important;
  color: #666666;
  font-size: 1.2em;
}

.btn-google {
  background-color: white;
  color: #666666 !important;
  width: 300px;
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  font-family: inherit !important;
  font-size: 1.1em !important;
  height: 50px;
  margin: 0 0 0 0 !important;
}

.btn-line {
  width: 300px;
  margin: 10px 0 0 0 !important;
  text-align: left !important;
  text-transform: none !important;
  border-radius: 5px !important;
  background-color: white !important;
  border: 1px solid green !important;
  box-shadow: none !important;
  color: green !important;
  padding-left: 6px !important;
  font-family: inherit !important;
  font-size: 1.1em !important;
  height: 50px;
  font-weight: 400 !important;
}
.btn-line span {
  justify-content: flex-start;
}
.btn-line .space {
  display: inline-block;
  width: 12px;
}

.btn-regis {
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
  display: inherit !important;
}
.btn-forget {
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
  display: inherit !important;
  float: right;
}
