.withdraw {
  display: block;
}

.withdraw .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}

.withdraw .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}

.withdraw .cart {
  text-align: right;
}

.withdraw .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}

.withdraw .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.withdraw .history-title{
  padding: 20px 20px 0 20px;
}

.withdraw .box-item {
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 5px;
}

.withdraw .box-data {
  background-color: #ffffff;
  margin: 5px 0;
  border-radius: 5px;
}

.withdraw .box-balance {
  background: linear-gradient(90deg, #e5e8f3, #f4f5f6 50%);
  padding: 5px 0;
  margin: 5px 0;
  border-radius: 5px;
}
.withdraw .icon {
  padding-left: 20px;
  color: #666666;
}
.withdraw .balance {
  padding-right: 20px;
  text-align: right;
  font-size: 1.5em;
}

.withdraw .text-topup {
  font-family: inherit;
  margin: 10px 0;
  color: #666666;
}

.withdraw .btn-price{
  font-family: inherit;
  font-size: 0.8rem;
  border-radius: 10px;
}

.withdraw .btn-submit{
  background: linear-gradient(90deg, #da1c0a, #b81f04 50%);
  font-family: inherit;
  font-size: 1em;
  border-radius: 15px;
}

.withdraw .MuiInputBase-input{
  text-align: right;
}