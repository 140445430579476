.home {
  display: block;
}

.home .appbar {
  background-color: transparent;
  border-bottom: 0;
  margin-bottom: 0;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
  border: 0;
  padding: 0;
  min-height: 50px;
  display: block;
}
.home .toolbar {
  padding: 0 !important;
  color: white;
}
.toolbar-icon {
  color: #e2e2e2;
}
.home .cart {
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
}
.home .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}
.home .avatar {
  margin-top: -6px;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.home .profileimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.home .textprofile {
  margin: 5px 0;
  text-align: center;
  text-shadow: 1px 1px #333333;
  font-size: 1.2em;
  color: #ffffff;
}
.home .spanprofile {
  display: inline-block;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  font-weight: 300;
}
.home .textbalance {
  text-shadow: 1px 1px #666666;
  font-size: 1.1em;
  padding-left: 10px;
}
.home .textpoint {
  text-shadow: 1px 1px #666666;
  font-size: 1.1em;
  text-align: right;
  padding-right: 10px;
}
.home .textspan {
  display: inline-block;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.home .header-bg {
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.home .slideshow {
  margin-top: 10px;
}
.home .slideshow .link {
  margin-right: 5px;
  display: block;
}
.home .slideshow .img {
  width: 100%;
  border-radius: 10px;
}

.home .navlink {
  /* background: linear-gradient(45deg, #95a5b8, #a1b5f0 50%); */
  /* display: block; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  text-align: center;
  border: 0;
  width: 100%;
  cursor: pointer;
  font-family: inherit;
}
.home .navicon {
  background: linear-gradient(145deg, #c9c9c9, #e2e2e2 40%);
  /* background-color: rgba(0, 0, 90, 0.5); */
  color: #071ca4;
  /* vertical-align: middle; */
  display: block;
  /* width: 40px; */
  width: 100%;
  height: 80px;
  margin: auto;
  padding-top: 20px;
  border-radius: 5px;
  font-size: 20px;
}
.home .navtext {
  margin-top: 5px;
  color: #060a77;
  font-size: 13px;
  font-weight: 400;
}
.home .text-primary {
  color: #e2e2e2;
}
.home .text-secondary {
  color: #999999;
  font-weight: 100;
}
.home .divider {
  background-color: #014f91;
}
.home .text-option-BUY {
  color: #28a745;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-option-SELL {
  color: #dc3545;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-price {
  color: #dddddd;
  text-align: right;
  font-size: 12px;
  font-family: sans-serif;
}
.home .text-option-TP {
  color: #19e048;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-option-SL {
  color: red;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-option-WIN {
  color: #28a745;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-option-LOSS {
  color: orangered;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}
.home .text-option-NONE {
  color: #999999;
  text-align: right;
  font-size: 0.9em;
  font-weight: 400;
}

.home .strength {
  margin: 20px 5px 10px 5px;
}
.home .strength td {
  font-size: 0.8rem;
  text-align: center;
  color: white;
  font-weight: 400;
}
.home .strength .sort_1 {
  color: lime;
}
.home .strength .sort_2 {
  color: chartreuse;
}
.home .strength .sort_3 {
  color: yellowgreen;
}
.home .strength .sort_4 {
  color: yellow;
}
.home .strength .sort_5 {
  color: orange;
}
.home .strength .sort_6 {
  color: darkorange;
}
.home .strength .sort_7 {
  color: orangered;
}
.home .strength .sort_8 {
  color: red;
}
