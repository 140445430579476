.pin {
  display: block;
}
.pin .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pin .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}
.pin .step {
  padding: 0 5px !important;
  margin-top: 20px;
  text-align: center;
}
.pin .step1 {
  color: #8ea1bb;
}
.pin .step2 {
  color: #8ea1bb;
}
.pin .steperror {
  color: #e94c4c;
}

.pin .box-input {
  margin: 20px 0 20px 0;
}
.pin .input {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0px 10px;
  background: transparent;
  border: 0;
  font-size: 3em;
  color: #1976d2;
}
.pin .box-btn td {
  text-align: center;
}
.pin .btn {
  font-size: 2em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: none;
  background-color: #f0f4fa;
  color: #8ea1bb;
}
.pin .btn-back {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.pin .btn-icon {
  font-size: 3em;
  color: #8ea1bb;
}
