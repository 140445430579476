:root {
  --whites: #ffffff;
  --icon: #064ba5;
  --coins: #f6da5d;
  --primary: #023f88;
  --secondary: #0448a0;
  --third: #023270;
  --bg1: #10102c;
  --bg2: #014f91;
  --footer1: #0353b6;
  --footer2: #022b60;
  --item1: #00428e;
  --item2: #012f6a;
  --hr: #3f8df8;
  --divider: rgb(255, 255, 255, 0.2);
  --price: #012f6a;
  --warning: #efb100;
  --btn: #f9fd05;
  --border: rgb(255, 255, 255, 0.2);
}
body {
  height: 100%;
  background: linear-gradient(135deg, var(--bg1), var(--bg2) 80%) fixed
    no-repeat;
}
a,
a:hover,
a:visited,
a:active,
a:focus {
  color: #666666;
}
span,
div {
  font-family: "Kanit", "Helvetica", "Arial", sans-serif;
}
.MuiTableCell-root {
  white-space: nowrap;
}
.MuiInput-underline:after {
  border-bottom: 2px solid var(--hr) !important;
}
.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}
.MuiTab-root {
  font-family: inherit !important;
  font-weight: 200 !important;
}
.react-countdown-clock {
  margin: auto;
}
.sub-game {
  text-align: center;
  color: #cccccc;
  font-size: 14px;
}
.hr-game {
  border-top: 2px solid var(--secondary);
  margin-bottom: 20px;
}
.header-title {
  font-size: 1.2em;
  padding: 10px 0;
  margin: 0;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.color-white {
  color: #ffffff;
}
.color-default {
  color: #999999;
}
.color-primary {
  color: #007bff;
}
.color-info {
  color: #17a2b8;
}
.color-success {
  color: #28a745;
}
.color-warning {
  color: #ffc107;
}
.color-danger {
  color: #dc3545;
}
.color-dark {
  color: #333333;
}

/* register */
.register {
  font-family: inherit;
  margin-bottom: 70px;
}
.register .box-topic {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}
.register .box-account {
  margin-bottom: 10px;
}
.register .box-img {
  text-align: center;
}
.register .text {
  color: white;
  margin-bottom: 10px;
  background-color: var(--third);
  padding: 5px 10px;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 300;
  font-size: 15px;
}
.register .text-title {
  margin: 0 2px 1px 5px;
  color: white;
  font-size: 15px;
}
.register .text-mask {
  background-color: transparent;
  color: white;
  font-family: inherit;
  border: 0;
  margin-left: 5px;
  font-size: 15px;
  letter-spacing: 3px !important;
  min-width: 200px;
}
.register .btn-submit {
  font-family: inherit;
  font-size: 15px;
}
.register .view {
  color: #999;
}
.register .MuiSelect-select.MuiSelect-select {
  padding: 5px;
}
.register .MuiInput-underline:after {
  border-bottom: 2px solid #923e3c;
}
.register .MuiFormHelperText-root {
  color: rgba(255, 255, 255, 0.54);
  font-family: inherit;
  margin: 0 0 10px 10px;
  font-weight: 300;
}

/* Block */
.block {
  font-family: inherit;
  margin-bottom: 70px;
}
.block .card {
  margin: 0;
  padding: 0;
}
.block .card-body {
  margin: 0;
  padding: 0;
}
.block .list {
  color: white;
}
.block .avatar {
  color: var(--primary);
  background-color: white;
}
.block .item {
  font-family: "Kanit", "Helvetica", "Arial", sans-serif;
  color: #333333;
  font-size: 1.2em;
}
.block .chevron {
  color: #333333;
}
.block .symbol {
  color: #333333;
  font-weight: 400;
}
.block .appbar-icon {
  color: white;
}
.block .appbar-title {
  color: white;
}
.block .table {
  border-collapse: collapse;
  border-color: #f2f2f2;
}
.block .table th {
  color: #333333;
  border-bottom: 1px solid #f2f2f2;
}
.block .table td {
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}
.block .img {
  width: 100%;
  border: 2px solid var(--border);
}
.block .table .td-1 {
  color: darkgreen;
}
.block .table .td-2 {
  color: rgb(5, 170, 5);
}
.block .table .td-3 {
  color: rgb(185, 216, 9);
}
.block .table .td-4 {
  color: rgb(223, 146, 4);
}
.block .table .td-5 {
  color: rgb(245, 79, 13);
}
.block .table .td-6 {
  color: rgb(233, 41, 7);
}
.block .table .td-7 {
  color: rgb(255, 78, 24);
}
.block .table .td-8 {
  color: rgb(153, 25, 3);
}
.block .table .td-9 {
  color: rgb(247, 89, 89);
}
.block .table tr:last-child th {
  border: 0;
}
.block .table tr:last-child td {
  border: 0;
}

.box-bg {
  background-color: white;
  border-radius: 5px;
  margin: 10px 0;
}

.bottom {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 1%;
  right: 1%;
  margin: auto;
  max-width: 414px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom .menu {
  font-size: 1.6em;
}

.MuiBottomNavigationAction-wrapper {
  font-size: 20px !important;
  margin-top: 5px;
}

.item-status {
  font-size: 12px;
  margin: 10px 0 10px 0;
  padding: 5px;
  color: #ffffff;
  border-radius: 5px;
}

.item-primary {
  background: linear-gradient(90deg, #1525bb, #2d2ad5 50%);
}

.item-info {
  background: linear-gradient(90deg, #2abce4, #129fd2 50%);
}

.item-success {
  background: linear-gradient(90deg, #147d2c, #229b1c 50%);
}

.item-warning {
  background: linear-gradient(90deg, #e1c527, #c39005 50%);
}

.item-danger {
  background: linear-gradient(90deg, #ca3a0e, #c71902 50%);
}

.timer-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
  padding: 10px;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.Mui-selected {
  color: #007bff;
}

.text-primary {
  color: #e2e2e2;
}
.text-secondary {
  color: #999999;
  font-weight: 100;
}
.arrow {
  color: #e2e2e2;
}
.divider {
  background-color: #014f91;
}
.img-round {
  border-radius: 50%;
}
