.deposit {
  display: block;
}

.deposit .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}

.deposit .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}

.deposit .cart {
  text-align: right;
}

.deposit .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}

.deposit .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.deposit .history-title{
  padding: 20px 20px 0 20px;
}

.deposit .box-item {
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 5px;
}

.deposit .box-data {
  background-color: #ffffff;
  margin: 5px 0;
  border-radius: 5px;
}

.deposit .box-balance {
  background: linear-gradient(90deg, #e5e8f3, #f4f5f6 50%);
  padding: 5px 0;
  margin: 5px 0;
  border-radius: 5px;
}
.deposit .icon {
  padding-left: 20px;
  color: #666666;
}
.deposit .balance {
  padding-right: 20px;
  text-align: right;
  font-size: 1.5em;
}

.deposit .text-topup {
  font-family: inherit;
  margin: 10px 0;
  color: #666666;
}

.deposit .btn-price{
  font-family: inherit;
  font-size: 0.8rem;
  border-radius: 10px;
}

.deposit .btn-submit{
  background: linear-gradient(90deg, #248406, #36b006 50%);
  font-family: inherit;
  font-size: 1em;
  border-radius: 15px;
}

.deposit .MuiInputBase-input{
  text-align: right;
}

.timer{
  font-family: inherit !important;
}