.setting {
  display: block;
}
.setting .toolbar {
  padding: 0 5px !important;
  color: white;
  min-height: 48px;
}
.setting .toolbar-icon {
  color: #ffffff;
  font-size: 18px;
}
.setting .cart {
  text-align: right;
}
.setting .MuiBadge-badge {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px;
  padding: 3px !important;
}
.setting .header-bg {
  background: linear-gradient(90deg, #536dfe, #2196f3 20%);
  padding: 0 !important;
  box-shadow: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.setting .img {
  width: 100%;
}

.setting .box-item {
  background-color: #ffffff;
  padding: 5px 0;
  margin: 5px 0;
  border-radius: 5px;
}

.setting .item-run {
  font-size: 12px;
  margin: 0;
  padding: 5px;
  color: #ffffff;
  border-radius: 5px;
  background: linear-gradient(90deg, #147d2c, #229b1c 20%);
}
.setting .item-img {
  width: 100%;
  border-radius: 5px;
}
.setting .item-price {
  font-size: 15px;
  margin: 0;
  padding-top: 5px;
  padding-left: 10px;
  color: #666666;
}
.setting .item-pv {
  font-size: 15px;
  margin: 0;
  padding-top: 5px;
  padding-left: 10px;
  color: #666666;
}
.setting .item-expire {
  font-size: 15px;
  margin: 0;
  padding-top: 5px;
  padding-left: 10px;
  color: #666666;
}
.setting .text-primary {
  color: #e2e2e2;
}
.setting .text-secondary {
  color: #999999;
  font-weight: 100;
}
.setting .divider {
  background-color: #014f91;
}
